@font-face {
  font-family: "Indie Flower";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(./fonts/indie-flower.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(./fonts/raleway.woff) format("woff");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}


body {
  color: white;
  font-family: Raleway, sans-serif;
  font-size: 20px;
  line-height: 24px;
  margin: 0;
}

#background-container,
#family-graph {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  line-height: 0;
}

#family-graph svg {
  height: 100%;
  width: 100%;
}

#title-card-container {
  position: absolute;
  top: 10px;
  left: 10px;
  right: 10px;
}

@media (min-width: 475px) {
  #title-card-container {
    right: unset;
  }
}

@media (min-width: 1200px) {
  #title-card-container {
    top: 20px;
    left: 20px;
  }
}

#member-card-container.visible {
  position: absolute;
  right: 10px;
  bottom: 10px;
  left: 10px;
}

@media (min-width: 475px) {
  #member-card-container.visible {
    left: unset;
  }
}

@media (min-width: 1200px) {
  #member-card-container.visible {
    right: 20px;
    bottom: 20px;
  }
}

#intro-card-container {
  position: absolute;
  display: inline-flex;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
}
